import { useState } from 'react'
import { timesHash } from '../components/shared/utils'
import useCurrentUser from '../components/helpers/useCurrentUser'
import { format } from 'date-fns'

export default function useResourcesRecordFormController(record) {
  const currentUser = useCurrentUser()
  const jobnumber = record.jobnumber
  const jobnumberId = (jobnumber && jobnumber.id) || 0

  const initialDate = record.date || format(new Date(), 'yyyy-MM-dd')
  const initialMachines = record.machines && record.machines.length > 0 ? record.machines : []
  const initialItems = record.items && record.items.length > 0 ? record.items : []
  const initialPackagings = record.packagings && record.packagings.length > 0 ? record.packagings : []
  const initialShipments = record.shipments && record.shipments.length > 0 ? record.shipments : []
  let initialTimes = []
  if (record.times && record.times.length > 0) {
    initialTimes = record.times
    if (record.partner_times && record.partner_times.length > 0) {
      initialTimes = initialTimes.concat(record.partner_times)
    }
  } else if (initialMachines.length === 0 && initialItems.length === 0 && initialPackagings.length === 0) {
    const defaultTimeRecord = {
      worktype_id: currentUser.default_worktype_id || '',
    }
    initialTimes = [defaultTimeRecord]
  }

  const [formData, setFormData] = useState({
    jobnumber_id: jobnumberId,
    task_id: record.task_id,
    date: initialDate,
    times_attributes: timesHash(initialTimes),
    machines_attributes: timesHash(initialMachines),
    items_attributes: timesHash(initialItems),
    packagings_attributes: timesHash(initialPackagings),
    shipments_attributes: timesHash(initialShipments),
    partner_ids: record.partner_ids || [],
    workcard_ids: record.workcard_ids || [],
  })


  const normalizeKeys = (obj: any) => {
    const result = {}
    Object.values(obj).forEach((v, k) => {
      result[k.toString()] = v
    })
    return result
  }

  const transform = data => {
    const transformedData = { ...data }
    transformedData.times_attributes = normalizeKeys(transformedData.times_attributes)
    transformedData.machines_attributes = normalizeKeys(transformedData.machines_attributes)
    transformedData.items_attributes = normalizeKeys(transformedData.items_attributes)
    transformedData.packagings_attributes = normalizeKeys(transformedData.packagings_attributes)
    transformedData.shipments_attributes = normalizeKeys(transformedData.shipments_attributes)
    return transformedData
  }

  return { formData, setFormData, transform }
}
